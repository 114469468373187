html {
  scroll-behavior: smooth;
}

body
{
  background: #EDF0F3;
  width: 100%;
  height: 100%;
  font-family: Helvetica, sans-serif !important;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
}

.section h3, .section h5, .section h4 {
  color: #525252;
  margin: 0;
}

h4 {
  font-family: Helvetica;
  font-weight: bold;
  font-size: 30px;
  color: #525252;
  text-align: left;
}

#what-we-do h5 {
  margin-bottom: 40px;
}

#what-we-do .scan {
  width: 25%;
  margin: 0;
  text-align: center;
}

#what-we-do .scan h4, #what-we-do .scan h5, #what-we-do .scan h3 {
  text-align: center;
  margin: 20px;
}

#what-we-do h3 {
  margin-bottom: 100px;
}

.scans.flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.App {
  display: flex;
  flex-flow: row wrap;
}

#about .left {
  margin-right: 40px;
  padding-right: 40px;
}

.section.split.forced-full {
  justify-content: space-between;
}

.section.split.forced-full .right img {
  position: relative;
  right: -8%;
  top: 120px;
}

.site-header {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text {
  position: relative;
}

.btn-holder {
  margin-top: 30px;
}

.button {
  margin-top: 20px;
}

.main-cta {
  position: absolute;
  top: 30%;
}

.main-cta h1 {
  margin: 0;
  font-family: Helvetica;
  /* font-weight bold; */
  font-size: 50px;
  color: #525252;
  text-align: left;
  /* line-height: 12px; */
}

.main-cta h3 {
  margin: 0;
  font-family: Helvetica;
  /* font-weight bold; */
  font-size: 20px;
  color: #525252;
  text-align: left;
}

.flex.apart {
  justify-content: space-between;
  width: 100%;
}

.App-header {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
  background: #000;
  width: 100%;
  font-size: calc(10px + 2vmin);
  color: white;
}

.section {
  padding: 4%;
  width: 100%;
  text-align: center;
}

.items .item {
  margin-right: 40px;
}

a {
  font-family: Helvetica;
  font-size: 20px;
  color: #FFFFFF;
  padding: 18px;
  border-right: 1px solid #fff;
  text-decoration: none;
}

a:last-of-type {
  border-right: 0;
}

.text {
  font-family: Helvetica;
  font-size: 25px;
  color: #000000;
  letter-spacing: 0;
}

button, .button {
  margin-top: 20px;
}

h4 {
  font-weight: normal;
}

h3, h3.barred-title a  {
  font-family: Helvetica;
  font-size: 40px;
  font-weight: bold;
  color: #000000;
  letter-spacing: 0;
}

h3.barred-title {
  border-bottom: 20px solid #004A80;
  padding-bottom: 10px;
  display: inline-block;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}

.scan {
  margin: 0 20px;
}

.split {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reversed.split {
  flex-direction: row-reverse;
}

.section.our-scans {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.scans.flex a {
  color: #000000;
  border-right: 0;
  margin: 0;
  padding: 0;
}

.main-cta h1 {
  margin: 20px 0px;
}

.scan h5 {
  line-height: 20px;
}

.main-cta h5 {
  color: #BCBCBC;
  font-family: Helvetica;
  font-weight: bold;
  font-size: 16px;
  color: #BCBCBC;
  text-align: left;
  margin-top: 6px;
  line-height: 28px;
  /* line-height: 12px; */
}

.email-subscribe {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 100px;
}

.email-subscribe input {
  width: 100%;
  border: none;
  border-radius: 20px;
  /* border-top-right-radius: 0px;
  border-bottom-right-radius: 0px; */
  padding: 15px;
  font-family: Helvetica;
  font-weight: bold;
  font-size: 16px;
  color: #BCBCBC;
  text-align: left;
  line-height: 12px;
}

.email-subscribe .btn {
  position: absolute;
  right: 4px;
}

#schedule {
  border-bottom: 1px solid black;
}

.dots {
  display: flex;
  justify-content: center;
}

.dots .dot {
  height: 18px;
  width: 18px;
  margin: 0px 10px;
  border-radius: 100%;
  background: #D8D8D8;
}

.dots .dot:hover {
  cursor: pointer;
}

.dots .dot.selected {
  background: #004A80;
}

span {
  color: #007A9C;
}

button, .button {
  background: #1CBBB4;
  padding: 17px;
  font-weight: bold;
  font-size: 20px;
  color: #FFFFFF;
  border: none;
}

button:hover, .button:hover {
  cursor: pointer;
}

.site-header, .footer {
  background: #D7D7D7;
  padding: 20px;
  color: #fff;
  font-family: Helvetica;
  font-size: 22px;
  color: #FFFFFF;
}

.fullscreen-bg, .text {
  display: inline-block;
  width: 48%;
  right: 2%;
}

.fullscreen-bg__video {
  width: 100%;
  height: 100%;
}

.text {
  display: inline-block;
}

.hidden {
  display: none;
}

.carousel .left, .carousel .right {
  min-width: 50%;
  min-height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  position: relative;
}

.carousel .left .content {
  height: 100%;
  width: 100%;
  min-height: 270px;
}

#schedule.carousel .left .content {
  min-height: 390px;
}

.carousel .top, .carousel .bottom {
  width: 100%;
  flex-grow: 1;
}

.carousel .left img, .carousel .right img {
  height: 442px;
  width: auto;
}

.section.footer {
  text-align: left;
  display: flex;
  background: #565656;
}

.footer .logo {
  border-right: 1px solid #fff;
  padding-right: 20px;
}

#about {
  /* padding-bottom: 0; */
}

.other h5, .other h4 {
  margin: 0;
}

.other h4 {
  font-family: Helvetica;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  color: #FFFFFF;
}

.other h5 {
  font-family: Helvetica;
  font-size: 12px;
  color: #FFFFFF;
  font-weight: normal;
}

h1 {
  font-family: Helvetica;
  font-weight: bold;
  font-size: 50px;
  color: #525252;
  text-align: left;
  line-height: 50px;
  margin: 60px 0;
}

.other .column {
  margin-right: 20px;
}

.hidden-text {
  visibility: hidden;
}

.other {
  width: 100%;
  display: flex;
  padding-left: 20px;
  font-family: Helvetica;
  font-size: 12px;
  color: #FFFFFF;
  justify-content: space-between;
  align-items: center;
}

.other .left {
  display: flex;
}

canvas {
  width: 100%;
  overflow: hidden;
}

.App-header {
  /* margin-top: 71px; */
}

#home, #banner {
  /* background: linear-gradient(270deg, rgba(132,213,208,0.04) 0%, rgba(126,207,203,0.04) 35%, rgba(77,170,163,0.04) 73%); */
  /* background: linear-gradient(270deg, rgba(132,213,208,0.00) 0%, rgba(126,207,203,0.15) 35%, rgba(77,170,163,0.15) 73%); */
  /* background: rgba(77,170,163,0.15);
  background: linear-gradient(126deg, rgba(77,170,163,1); 0%, rgba(255,255,255,1) 71%, rgba(77,170,163,1); 100%); */
  background: rgb(0,212,255);
  background: linear-gradient(126deg, #DEEFED 0%, rgba(255,255,255,1) 65%, #DEEFED 100%);
}

#home {
  max-height: 90vh;
}

#banner {
  background-image: linear-gradient(90deg, rgba(255,255,255,0.12) 0%, rgba(168,229,225,0.12) 15%, rgba(139,216,211,0.23) 28%, rgba(132,213,208,0.25) 98%);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

#banner h1, #banner div.ctn {
  width: 100%;
  line-height: 50px;
}

#banner div.ctn {
  display: flex;
  align-items: center;
  justify-content: center;
}

#banner div.ctn .btn {
  max-width: 100px;
  text-align: center;
  margin-top: 40px;
}

.btn {
  transition: all 100ms linear;
}

.btn:hover {
  cursor: pointer;
  background: #0694BB;
}

#banner h1 {
  font-family: Helvetica;
  font-weight: bold;
  font-size: 50px;
  color: #FFFFFF;
  text-align: left;
  line-height: 50px;
  text-align: center;
  margin: 0;
}

.btn {
  background: #007A9C;
  /* border: 1px solid #FFFFFF; */
  border-radius: 17px;
  color: white;
  font-family: Helvetica;
  font-weight: bold;
  font-size: 12px;
  color: #FFFFFF;
  text-align: left;
  line-height: 12px;
  padding: 13px;
}

.header {
  /* box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1); */
  /* position: fixed; */
  width: 100%;
  z-index: 3;
  /* background: #D7D7D7; */
  padding-top: 10px;
  color: #fff;
  font-family: Helvetica;
  font-size: 22px;
  color: #FFFFFF;
}

ul.menu {
  align-items: center;
  display: flex;
}

ul.menu a {
  font-family: Helvetica;
  font-size: 12px;
  color: #BCBCBC;
  text-align: left;
  line-height: 12px;
}

ul.menu .btn {
  display: inline-block;
}

ul.menu .btn a {
  padding: 0;
  font-family: Helvetica;
  font-weight: bold;
  font-size: 12px;
  color: #FFFFFF;
  text-align: left;
  line-height: 12px;
}

.header {
  margin-top: 20px;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  margin-right: 20px;
  /* background: #D7D7D7; */
}

.phone-img {
  position: relative;
  top: 0px;
  left: 50px;
}

#scans, #comp, .section {
  z-index: 10;
  background: #fff;
}

.header li{

}

.header .logo {
  display: block;
  float: left;
  font-size: 2em;
  padding: 0px 20px;
  text-decoration: none;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 340px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header li a {
    padding: 20px 30px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}

#comp h1 {
  margin: 0;
  font-family: Helvetica;
  /* font-weight bold; */
  font-size: 50px;
  color: #525252;
  text-align: left;
  margin: 15px 0;
}

#comp h3 {
  margin: 0;
  font-family: Helvetica;
  /* font-weight bold; */
  font-size: 20px;
  color: #525252;
  text-align: left;
}

#comp h5 {
  margin: 0;
  font-family: Helvetica;
  /* font-weight bold; */
  font-size: 16px;
  line-height: 28px;
  color: #BCBCBC;
  text-align: left;
}

.btn.square {
  border-radius: 10px;
  padding: 28px;
  font-family: Helvetica;
  font-weight: bold;
  font-size: 20px;
  color: #FFFFFF;
  text-align: left;
  line-height: 12px;

  margin-top: 100px;
}

.App {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.flex.apart {
  align-items: flex-start;
}

@media(max-width: 800px) {
  video.video-inside-comp {
    width: 100%;
    position: absolute;
    top: 20%;
    left: 15%;
    width: 67.5%;
    /* height: 1000px; */
  }


  #home {
    max-height: 100vh;
  }

  h3, h3.barred-title a  {
    font-size: 24px;
  }

  .header .menu {
    position: absolute;
    right: 50px;
  }

  #what-we-do .scans .scan {
    width: 100%;
  }

  #home .text {
    position: absolute;
    top: 200px;
    width: 90%;
    text-align: center;
    left: 5%;
  }

  .bottom {
    font-size: 12px;
  }

  .section.info {
    align-items: flex-start;
  }

  .items.flex {
    justify-content: center;
    align-items: flex-start;
  }

  #about, #comp {
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    max-width: 95%;
  }

  /* #about img, #comp img {
    max-width: 200px;
  } */

  #comp, #about {
    max-width: 95%;
  }

  #comp img {
    max-width: 95%;
  }

  #about .left {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  #about .left img {
    max-width: 340px;
  }

  .flex {
    flex-flow: row wrap;
  }

  .flex .left, .flex .right {
    width: 100%;
  }

  .items .item img {
    width: 80%;
    height: auto;
  }

  .footer .column.second,
  .footer .column.third,
  .footer .column.fourth,
  .footer .column.fifth {
    display: none;
  }

  .carousel .left img, .carousel .right img {
    height: 150px;
    width: auto;
  }

  .carousel .left .content {
    height: 150px;
  }

  .carousel .left .content .calendar-item {
    padding: 20px;
  }

  .carousel .left .content .calendar-item h3 {
    font-size: 16px;
  }

  .carousel .left .content .calendar-item h5 {
    font-size: 14px;
  }

  .carousel .left .content .calendar-item button, .carousel .left .content .calendar-item .button {
    font-size: 14px;
  }

  .text {
    width: 100%;
    font-size: 20px;
  }
}

@media(max-width: 1000px) {
  .main-cta {
    top: 20%;
    width: 100%;
    font-size: 16px;
    left: 0;
  }
}

.calendar-item {
  background: #FFFFFF;
  border: 3px solid #004A80;
  display: inline-block;
  padding: 20px;
}

.calendar-item h3 {
  font-size: 30px;
  font-weight: bold;
  color: #007A9C;
  letter-spacing: 0;
  margin: 0;
}

.calendar-item h5 {
  font-family: Helvetica;
  font-size: 25px;
  color: #000000;
  letter-spacing: 0;
  margin: 0;
}

.calendar-item .button {
  display: block;
  margin-top: 20px;
}

.footer a {
  font-size: 12px;
  padding: 0;
}

.notification-ctn {
  box-shadow: 0 0 15px 0 rgba(#000, 0.35);
  position: fixed;
  top: 20px;
  right: 40px;
  padding: 10px;
  color: #fff;
  background: #f5f5f5;
  z-index: 100;
  font-family: Helvetica;
  font-weight: bold;
  font-weight: bold;
  font-size: 14px;
  border-radius: 4px;
  transition: all 100ms linear;
  animation: fade-in 100ms ease-in-out forwards;
  background: #58d656;
}

video {
  width: 100%;
  position: absolute;
  top: 20%;
  left: 13%;
  width: 71.5%;
  /* height: 1000px; */
}

.comp-holder {
  position: relative;
  height: 100%;
  width: 100%;
}

.notification-ctn:hover {
    cursor: pointer;
}
